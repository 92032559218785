import React from 'react';

import { Carousel, Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';

import { LandingDivider } from '../app-components/landingDivider';
import { englishStyling, isEnglish, kannadaStyling } from '../static/translations/constants';
import buyerPicture from '../static/assets/Buyer.png';
import sellerPicture from '../static/assets/Seller.png';
import marketPicture from '../static/assets/Market1.png';
import line21 from '../static/assets/Line 21.png';

const { Text, Title } = Typography;

const Aim = () => {
    const { t } = useTranslation('common');
    const customStyles = isEnglish(t('language')) ? englishStyling : kannadaStyling;

    return (
        <>
            <div id="aim">
                <div className="aim-wrapper">
                    <Row justify="center" className="aimFirstRow">
                        <Col md={12} className="aim-Whatis">
                            <Title className="aim-header">{t('aim_page.text1')}</Title>
                            <p>{t('aim_page.text2')}</p>
                        </Col>
                        <Col md={12} className="videoContent">
                            <iframe
                                className="videoContent"
                                width="400"
                                height="300"
                                border-radius="10%"
                                style={{ marginLeft: '50px' }}
                                src ={isEnglish(t('language'))?"https://www.youtube.com/embed/p4CZAGgJ-wM":"https://www.youtube.com/embed/0ueB09CugRc"}
                            />
                        </Col>
                    </Row>
                    {/* <LandingDivider className="divider" /> */}
                    <Title className="why-vikasBandhu">{t('aim_page.text3')}</Title>
                    <Text className="at-present-text">{t('aim_page.text4')}</Text>

                    <div className="ExistingMarketPlaces">
                        <Row className="firstBox">
                            <Text className="firstBoxHeading">{t('aim_page.text27')} </Text>
                        </Row>
                        <Row className="secondBox">
                            <Col xs={24} md={4} xl={4} span={4} className="existingPicture">
                                <img
                                    style={{ width: '150px', height: '130px', position: 'relative', top: '-10px' }}
                                    src={marketPicture}
                                    alt="PriceDiscovery"
                                />
                            </Col>
                            <Col xs={24} md={20} xl={20} span={20} className="insidePara">
                                <Row className="insideRows">
                                    <Col span={4} className="points">
                                        <div style={{ background: 'rgba(255, 62, 62, 0.1)' }}>
                                            01
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <p>{t('aim_page.text29')}</p>
                                    </Col>
                                </Row>
                                <Row className="insideRows">
                                    <Col span={4} className="points">
                                        <div style={{ background: 'rgba(255, 62, 62, 0.1)' }}>
                                            02
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <p>{t('aim_page.text30')}</p>
                                    </Col>
                                </Row>
                                <Row className="insideRows">
                                    <Col span={4} className="points">
                                        <div style={{ background: 'rgba(255, 62, 62, 0.1)' }}>
                                            03
                                        </div>
                                    </Col>
                                    <Col span={20}>
                                        <p>{t('aim_page.text31')}</p>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>

                    <div className="BuyerSellerRow">
                        <div className="mdScreen">
                            <Row className="BuyerSellerAdvantage">
                                <Col className="sellerBox inside">
                                    <Row className="headText">
                                        <p className="span-text">{t('aim_page.text5')}</p>
                                        <p>{t('aim_page.text6')}</p>
                                    </Row>
                                    <Row className="CImage">
                                        <img src={sellerPicture} alt="PriceDiscovery" />
                                    </Row>
                                    <Row className="pointsSection">
                                        <Row className="sellerBuyerPoints">
                                            <Col span={4}>
                                                <div
                                                    style={{ background: `rgb(247, 104, 43,0.1)` }}
                                                >
                                                    01
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <p>{t('aim_page.text9')}</p>
                                            </Col>
                                        </Row>
                                        <Row className="sellerBuyerPoints">
                                            <Col span={4}>
                                                <div
                                                    style={{ background: `rgb(247, 104, 43,0.1)` }}
                                                >
                                                    02
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <p>{t('aim_page.text10')}</p>
                                            </Col>
                                        </Row>
                                        <Row className="sellerBuyerPoints">
                                            <Col span={4}>
                                                <div
                                                    style={{ background: `rgb(247, 104, 43,0.1)` }}
                                                >
                                                    03
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <p>{t('aim_page.text11')}</p>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Col>
                                <Col className="buyerBox inside">
                                    <Row className="headText">
                                        <p className="span-text">{t('aim_page.text7')}</p>
                                        <p>{t('aim_page.text8')}</p>
                                    </Row>
                                    <Row className="CImage">
                                        <img src={buyerPicture} alt="PriceDiscovery" />
                                    </Row>
                                    <Row className="pointsSection">
                                        <Row className="sellerBuyerPoints">
                                            <Col span={4}>
                                                <div
                                                    style={{
                                                        background: `rgb(245, 163, 26, 0.1)`,
                                                        color: '#F5A31A',
                                                    }}
                                                >
                                                    01
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <p>{t('aim_page.text15')}</p>
                                            </Col>
                                        </Row>
                                        <Row className="sellerBuyerPoints">
                                            <Col span={4}>
                                                <div
                                                    style={{
                                                        background: `rgb(245, 163, 26, 0.1)`,
                                                        color: '#F5A31A',
                                                    }}
                                                >
                                                    02
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <p>{t('aim_page.text16')}</p>
                                            </Col>
                                        </Row>
                                        <Row className="sellerBuyerPoints">
                                            <Col span={4}>
                                                <div
                                                    style={{
                                                        background: `rgb(245, 163, 26, 0.1)`,
                                                        color: '#F5A31A',
                                                    }}
                                                >
                                                    03
                                                </div>
                                            </Col>
                                            <Col span={20}>
                                                <p>{t('aim_page.text17')}</p>
                                            </Col>
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                        <div className="mobileBuyerSellerRow">
                            <Col span={24} xs={24} sm={24} md={0}>
                                <Row className="BuyerSellerAdvantage">
                                    <Carousel autoplay dots={true}>
                                        <div>
                                            <div className="sellerBox inside">
                                                <Row className="headText">
                                                    <p className="span-text">
                                                        {t('aim_page.text5')}
                                                    </p>
                                                    <p>{t('aim_page.text6')}</p>
                                                </Row>
                                                <Row className="CImage">
                                                    <img
                                                        src={sellerPicture}
                                                        alt="PriceDiscovery"
                                                    />
                                                </Row>
                                                <Row className="pointsSection">
                                                    <Row className="sellerBuyerPoints">
                                                        <Col span={4}>
                                                            <div
                                                                style={{
                                                                    background: `rgb(247, 104, 43,0.1)`,
                                                                    color: '#F6682B',
                                                                }}
                                                            >
                                                                01
                                                            </div>
                                                        </Col>
                                                        <Col span={20}>
                                                            <p>
                                                                {t('aim_page.text9')}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="sellerBuyerPoints">
                                                        <Col span={4}>
                                                            <div
                                                                style={{
                                                                    background: `rgb(247, 104, 43,0.1)`,
                                                                    color: '#F6682B',
                                                                }}
                                                            >
                                                                02
                                                            </div>
                                                        </Col>
                                                        <Col span={20}>
                                                            <p>
                                                                {t('aim_page.text10')}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="sellerBuyerPoints">
                                                        <Col span={4}>
                                                            <div
                                                                style={{
                                                                    background: `rgb(247, 104, 43,0.1)`,
                                                                    color: '#F6682B',
                                                                }}
                                                            >
                                                                03
                                                            </div>
                                                        </Col>
                                                        <Col span={20}>
                                                            <p>
                                                                {t('aim_page.text11')}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </div>
                                        </div>
                                        <div>
                                            <div className="buyerBox inside">
                                                <Row className="headText">
                                                    <p className="span-text">
                                                        {t('aim_page.text7')}
                                                    </p>
                                                    <p>{t('aim_page.text8')}</p>
                                                </Row>
                                                <Row className="CImage">
                                                    <img
                                                        src={buyerPicture}
                                                        alt="PriceDiscovery"
                                                    />
                                                </Row>
                                                <Row className="pointsSection">
                                                    <Row className="sellerBuyerPoints">
                                                        <Col span={4}>
                                                            <div
                                                                style={{
                                                                    background: `rgb(245, 163, 26, 0.1)`,
                                                                    color: '#F5A31A',
                                                                }}
                                                            >
                                                                01
                                                            </div>
                                                        </Col>
                                                        <Col span={20}>
                                                            <p>
                                                                {t('aim_page.text15')}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="sellerBuyerPoints">
                                                        <Col span={4}>
                                                            <div
                                                                style={{
                                                                    background: `rgb(245, 163, 26, 0.1)`,
                                                                    color: '#F5A31A',
                                                                }}
                                                            >
                                                                02
                                                            </div>
                                                        </Col>
                                                        <Col span={20}>
                                                            <p>
                                                                {t('aim_page.text16')}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                    <Row className="sellerBuyerPoints">
                                                        <Col span={4}>
                                                            <div
                                                                style={{
                                                                    background: `rgb(245, 163, 26, 0.1)`,
                                                                    color: '#F5A31A',
                                                                }}
                                                            >
                                                                03
                                                            </div>
                                                        </Col>
                                                        <Col span={20}>
                                                            <p>
                                                                {t('aim_page.text17')}
                                                            </p>
                                                        </Col>
                                                    </Row>
                                                </Row>
                                            </div>
                                        </div>
                                    </Carousel>
                                </Row>
                            </Col>
                        </div>
                    </div>

                    <div className="line21">
                        <img src={line21}></img>
                    </div>
                    <Text className="addressText"> {t('aim_page.text32')}</Text>
                    <Row className="adressAboveIsusse">
                        <Text className="addressBriefText ">
                            {t('aim_page.text33')}
                            <span>“VikasBandhu”</span> {t('aim_page.text34')} {t('aim_page.text35')}
                        </Text>
                    </Row>
                    <LandingDivider className="pageDivider" />
                </div>
            </div>
        </>
    );
};

export default Aim;
