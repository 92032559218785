import React from 'react';
import { Typography, Button, Carousel, Modal, Breadcrumb, Row, Col } from 'antd';
import { History } from 'history';
import { useTranslation } from 'react-i18next';

import { isEnglish } from '../static/translations/constants';
import { LandingDivider } from '../app-components/landingDivider';
import Register from '../login-ui/register';
import googlePlay from '../static/assets/googleimage.png';
import firstImage from '../static/assets/landingImage.png';
import secondImage from '../static/assets/Vikasbandhu_Five.jpg';
import thirdImage from '../static/assets/Vikasbandhu_One.jpg';
import fourthImage from '../static/assets/Vikasbandhu_Two.jpg';
import fifthImage from '../static/assets/Image_Govt1.jpg';

const { Title } = Typography;

const Home = ({
    history,
    popUpTrigger,
    setSignUpPopupVisible,
}: {
    history: History;
    popUpTrigger: any;
    setSignUpPopupVisible: Function;
}) => {
    const { signUpPopupVisible } = popUpTrigger;
    const { t } = useTranslation('common');
    const isEnglishTrans = isEnglish(t('language'))

    return (
        <div id="home">
            <div className="home-wrapper">
                <Row>
                    <Col span={10} xs={24} md={10}>
                        <Title
                            level={2}
                            className={isEnglishTrans ? 'homeTitle' : 'homeTitleKan'}
                        >
                            {t('home_page.text8')}{t('home_page.text9')}.{t('home_page.text10')}.{t('home_page.text11')}
                        </Title>
                        <Title level={5}>{t('home_page.text1')}</Title>
                        <div className="wimage"></div>
                        <div className="registerComponent">
                            <p>{t('home_page.text2')}</p>
                            <Button onClick={() => setSignUpPopupVisible(!signUpPopupVisible)}>
                                Register
                            </Button>
                            <Modal
                                title={null}
                                open={signUpPopupVisible}
                                footer={null}
                                maskClosable={false}
                                className="custom-register-modal"
                                onCancel={() => setSignUpPopupVisible(!signUpPopupVisible)}
                                centered
                                wrapClassName="register-popup-container"
                            >
                                <Register
                                    history={history}
                                    setSignUpPopupVisible={setSignUpPopupVisible}
                                />
                            </Modal>
                        </div>
                        <div className="googlePlay">
                            <Button
                                size="small"
                                type="link"
                                href="https://play.google.com/store/apps/details?id=com.vikasBandhu"
                                target="_blank"
                            >
                                <img src={googlePlay} />
                            </Button>
                        </div>
                        <div className="headFooter">
                            <div className="firstL">
                                <p>{t('home_page.text3')}</p>
                                <a href="#updates">{t('home_page.text4')}</a>
                            </div>
                            <div className="secondL">
                                <Breadcrumb separator="|">
                                    <Breadcrumb.Item href="#iiiTBanglore">
                                        {t('home_page.text5')}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="#buyerSellerCon">
                                        {t('home_page.text6')}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="#patent">
                                        {t('home_page.text7')}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item href="#Fpos">
                                        {t('home_page.text12')}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                            </div>
                        </div>
                    </Col>
                    <Col span={14} xs={0} md={10}>
                        <div className="courselCircle">
                            <Carousel
                                autoplay
                                speed={2500}
                                dots={false}
                                effect="fade"
                                className="courselUnit"
                            >
                                <img src={firstImage} className="contentStyle" alt="firstImage" />
                                <img src={secondImage} className="contentStyle" alt="secondImage" />
                                <img src={thirdImage} className="contentStyle" alt="thirdImage" />
                                <img src={fourthImage} className="contentStyle" alt="fourthImage" />
                                <img src={fifthImage} className="contentStyle" alt="fifthImage" />
                            </Carousel>
                        </div>
                    </Col>
                </Row>
            </div>
            <LandingDivider className="pageDivider" />
        </div>
    );
};

export default Home;
