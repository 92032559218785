import React, { useState } from 'react';
import { Collapse, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { CaretRightOutlined,CaretDownOutlined } from '@ant-design/icons';

import mvp from '../static/assets/VbThree.png';
import foodExb from '../static/assets/VbTwo.jpg';
import academia from '../static/assets/VbFive.jpg';
import patents from '../static/assets/patentsFiled.png';
import buyerSellerCon from '../static/assets/buyerSellerCon.jpeg';
import Fpos from '../static/assets/FPO (1).jpg';
import IIITBang from '../static/assets/IIITBangalore.jpeg';

const { Title, Text } = Typography;

const Updates = () => {
    const [showOldUpdates, setShowOldUpdates] = useState(false);
    const { t } = useTranslation('common');
    const { Panel } = Collapse;

    return (
        <div id="updates">
            <div className="updateWrapper">
                <Title className="updates-title">{t('updates_page.title')}</Title>
                <Collapse
                    accordion
                    bordered={false}
                    // defaultActiveKey={['3']}
                    expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                    className="site-collapse-custom-collapse"
                >
                    <Panel
                        id="iiiTBanglore"
                        header={t('updates_page.title7')}
                        key="1"
                        className="site-collapse-custom-panel"
                    >
                        <div className="card">
                            <img src={IIITBang} alt="foodExb"></img>
                            <p>{t('updates_page.text7')}</p>
                        </div>
                    </Panel>
                    <Panel
                        id="buyerSellerCon"
                        header={t('updates_page.title4')}
                        key="2"
                        className="site-collapse-custom-panel"
                    >
                        <div className="card">
                            <img src={buyerSellerCon} alt="academia"></img>
                            <p>{t('updates_page.text4')}</p>
                        </div>
                    </Panel>
                    <Panel
                        id="patent"
                        header={t('updates_page.title5')}
                        key="3"
                        className="site-collapse-custom-panel"
                    >
                        <div className="card">
                            <img src={patents} alt="mvp"></img>
                            <p>{t('updates_page.text5')}</p>
                        </div>
                    </Panel>
                    <Panel
                        id="Fpos"
                        header={t('updates_page.title6')}
                        key="4"
                        className="site-collapse-custom-panel"
                    >
                        <div className="card">
                            <img src={Fpos} alt="FposExb"></img>
                            <p>{t('updates_page.text6')}</p>
                        </div>
                    </Panel>
                </Collapse>
                <div
                    className="oldUpdates"
                    onClick={() => {
                        showOldUpdates ? setShowOldUpdates(false) : setShowOldUpdates(true);
                    }}
                >
                    {showOldUpdates ?<CaretDownOutlined />:<CaretRightOutlined />}
                    <Text>{t('updates_page.text8')}</Text>
                </div>
                {showOldUpdates ? (
                    <Collapse
                        accordion
                        bordered={false}
                        // defaultActiveKey={['3']}
                        expandIcon={({ isActive }) => (
                            <CaretRightOutlined rotate={isActive ? 90 : 0} />
                        )}
                        className="site-collapse-custom-collapse"
                    >
                        <Panel
                            id="academia"
                            header={t('updates_page.title3')}
                            key="3"
                            className="site-collapse-custom-panel"
                        >
                            <div className="card">
                                <img src={academia} alt="academia"></img>
                                <p>{t('updates_page.text3')}</p>
                            </div>
                        </Panel>
                        <Panel
                            id="mvp"
                            header={t('updates_page.title1')}
                            key="1"
                            className="site-collapse-custom-panel"
                        >
                            <div className="card">
                                <img src={mvp} alt="mvp"></img>
                                <p>{t('updates_page.text1')}</p>
                            </div>
                        </Panel>
                        <Panel
                            id="foodExb"
                            header={t('updates_page.title2')}
                            key="2"
                            className="site-collapse-custom-panel"
                        >
                            <div className="card">
                                <img src={foodExb} alt="foodExb"></img>
                                <p>{t('updates_page.text2')}</p>
                            </div>
                        </Panel>
                    </Collapse>
                ) : null}
            </div>
        </div>
    );
};

export default Updates;
