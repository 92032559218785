import React from 'react';
import { Modal,Typography } from 'antd';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import { MatchRequirementModel } from '../../buyer-seller-commons/types';


const { Title} = Typography;

export const getTransactionDataStructure = (cropDetails: MatchRequirementModel) => {
    const {
        fulfillment_flag, produce, matched_quantity, seller_crop_id, seller_id, seller_quantity,buyer_remaining_quantity,seller_remaining_quantity,
        seller_final_price, seller_price, seller_location, seller_facilitation_cost, seller_transportation_cost,seller_facilitation_gst,
        seller_final_price_per_quintal, buyer_id, buyer_crop_id, buyer_location, buyer_facilitation_cost,seller_total_price,seller_initial_produce_cost,seller_quoted_price_per_quintal,
        buyer_transportation_cost, buyer_final_price, buyer_actual_quantity, buyer_price_per_quintal,buyer_produce_gst_amount,seller_produce_gst_amount,buyer_total_price,buyer_facilitation_gst,Installment_count
    } = cropDetails;

    const transactionEntry = {
        transaction_type: fulfillment_flag,
        matched_quantity,
        produce,
        grade: '111',
        buyer: [{
            buyer_id,
            buyer_location,
            buyer_quantity: buyer_actual_quantity,
            buyer_price: 0,
            buyer_final_price,
            buyer_transportation_cost,
            buyer_facilitation_cost,
            buyer_crop_id,
            buyer_price_per_quintal,
            buyer_produce_gst_amount,
            buyer_total_price,
            buyer_remaining_quantity,
            buyer_facilitation_gst,
            Installment_count
        }],
        seller: [{
            seller_id,
            seller_location: seller_location,
            seller_quantity,
            seller_price,
            seller_transportation_cost,
            seller_facilitation_cost,
            seller_final_price,
            seller_crop_id,
            seller_total_price,
            seller_facilitation_gst,
            seller_final_price_per_quintal,
            seller_remaining_quantity,
            seller_initial_produce_cost,
            seller_quoted_price_per_quintal,
            seller_produce_gst_amount,
            Installment_count
        }]
    };

    return transactionEntry;
};

export const displayMatchSuccessModal = () => {
    return Modal.success({
        className: 'match-success-modal',
        icon: '',
        centered: true,
        title: <CheckCircleFilled className='match-success-icon' />,
        closable:false,
        content: (
            <>
                <Title className='text-align-center' level={5}>
                    Request has been sent to seller
                </Title>
                <Title className='text-align-center' level={5}>
                    You will be notified from Vikasbandhu once the seller accepts/ rejects
                </Title>
            </>),
        okText: 'Done',
        okButtonProps: { type: 'default' }
    });
};

export const displayConcurrentMatchError = () => {
    return Modal.error({
        className: 'match-success-modal',
        icon: '',
        centered: true,
        title: <ExclamationCircleFilled className='match-error-icon' />,
        content: (
            <>
                <p className='modal-info-text'>
                    This request has timed out and is no longer valid.
                </p>
                <p className='modal-info-text' >
                    Vikasbandhu will search for a new seller
                </p>
            </>),
        okText: 'Ok',
        okButtonProps: { type: 'text' }
    });
};
