import React, { useEffect, useState } from 'react';
import { Button, Col, Modal, Row, Spin, Table, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { WarningOutlined } from '@ant-design/icons';
import { PlusOutlined, MinusOutlined } from '@ant-design/icons';

import ViewCropDetails from './viewCropDetails';
import { MatchRequirementModel, TransactionAction } from '../types';
import { rejectMatchesCount } from '../actions';

import { RootState } from '../../store/rootReducer';
import { getProduceList, rejectMatches } from '../../store/buyerReducer/actions';
import { getAllSellerMatches, transactionAction } from '../../store/sellerReducer/actions';
import Refresh from '../../static/assets/refresh.png';
import { parseIDfromHash } from '../../app-components/utils';
import { matchesSellerColumns } from '../../seller-ui/matches/matchesTable.model';
import { isEmpty } from 'lodash';
import CombineData from './combineData';
import SingleFullDataTable from './singleFullDataTable';
import ConnectMatches from './connectMatches';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

export interface componentCallBacksModel {
    showCropDetailsModal: any;
    showConnectModal: any;
    populateCropDetails: any;
    rejectMatch: any;
}

const Matches = () => {
    const [openDetailsModal, setOpenDetailsModal] = useState(false);
    const [displayConnect, setDisplayConnect] = useState(false);
    const [selectedCropDetails, setSelectedCropDetails]: any = useState([]);
    const [reloadClicked, setReloadClicked] = useState(0);
    const [cardOpen, setCardOpen] = useState(null);
    const [viewAgreement, setViewAgreement] = useState(false);
    const dispatch = useDispatch();
    const loginState = useSelector((state: RootState) => state.loginUser);
    const { is_buyer } = loginState;
    const userState = useSelector((state: RootState) => (is_buyer ? state.buyer : state.seller));
    const idName = is_buyer ? 'buyer-ui-matches' : 'seller-ui-matches';
    const { t } = useTranslation('common');

    const matchListData = userState.matchesList;

    useEffect(() => {
        !is_buyer && dispatch(getAllSellerMatches());
    }, []);

    useEffect(() => {
        if (reloadClicked === 5) {
            setTimeout(() => {
                setReloadClicked(0);
            }, 500000);
        }
    }, [reloadClicked]);

    const rejectBuyerMatch = (curMatchRecord: any) => {
        const combineData = curMatchRecord?.combinedMatchOne;
        if (combineData) {
            const keys = Object.keys(curMatchRecord);
            keys.forEach((key, index) => {
                const cropDetails: any = curMatchRecord[key];
                const { buyer_id, buyer_crop_id, seller_id, seller_crop_id, matched_quantity } =
                    cropDetails;
                const user_id = buyer_id.substring(5);
                const crop_id = buyer_crop_id.substring(11);
                const rejectCountData = { user_id, crop_id, user: 'buyer' };
                const { rejectCount } = userState;
                dispatch(rejectMatchesCount(rejectCountData));
                if (rejectCount === 3 || rejectCount === 5) {
                    Modal.confirm({
                        title: '',
                        icon: <WarningOutlined />,
                        content:
                            rejectCount === 3
                                ? 'You are rejecting the match for the 3rd time, If you wish to continue, your matches will be blocked, and you will not be getting any new matches.'
                                : 'You are rejecting the match for the 5th time, If you wish to continue, you are not able to add any requirements for next 7 days, your account will be blocked',
                        okText: 'Reject',
                        onOk() {
                            dispatch(
                                rejectMatches({
                                    buyer_id,
                                    buyer_crop_id,
                                    seller_id,
                                    seller_crop_id,
                                    matched_quantity,
                                }),
                            );
                        },
                        cancelText: 'Cancel',
                        onCancel() {},
                    });
                } else {
                    dispatch(
                        rejectMatches({
                            buyer_id,
                            buyer_crop_id,
                            seller_id,
                            seller_crop_id,
                            matched_quantity,
                        }),
                    );
                }
            });
        } else {
            const { buyer_id, buyer_crop_id, seller_id, seller_crop_id, matched_quantity } =
                curMatchRecord;
            const user_id = buyer_id.substring(5);
            const crop_id = buyer_crop_id.substring(11);
            const rejectCountData = { user_id, crop_id, user: 'buyer' };
            const { rejectCount } = userState;
            
            dispatch(rejectMatchesCount(rejectCountData));

            if (rejectCount === 5 || rejectCount === 15) {
                Modal.confirm({
                    title: '',
                    icon: <WarningOutlined />,
                    content:
                        rejectCount === 5
                            ? 'You are rejecting the match for the 5th time, If you wish to continue, your add produce will be blocked, and you are not able to add any new crops.'
                            : 'You are rejecting the match for the 15th time, If you wish to continue, you will not be getting any new matches for next 7 days, your account will be blocked',
                    okText: 'Reject',
                    onOk() {
                        dispatch(
                            rejectMatches({
                                buyer_id,
                                buyer_crop_id,
                                seller_id,
                                seller_crop_id,
                                matched_quantity,
                            }),
                        );
                    },
                    cancelText: 'Cancel',
                    onCancel() {},
                });
            } else {
                dispatch(
                    rejectMatches({
                        buyer_id,
                        buyer_crop_id,
                        seller_id,
                        seller_crop_id,
                        matched_quantity,
                    }),
                );
            }
        }
    };

    const rejectSellerMatch = (matchRecord: MatchRequirementModel) => {
        const { pk = '', seller_crop_id } = matchRecord;
        const user_id = loginState?.phone_no;
        const crop_id = seller_crop_id.substring(12);
        const rejectCountData = { user_id, crop_id, user: 'seller' };
        dispatch(rejectMatchesCount(rejectCountData));
        const { rejectCount } = userState;
        if (rejectCount === 5) {
            Modal.confirm({
                title: '',
                icon: <WarningOutlined />,
                content:
                    'You are rejecting the match for the 5th time, If you wish to continue, your matches will be blocked, and you will not be getting any new matches you are not able to add any requirements for next 7 days, your account will be blocked',
                okText: 'Reject',
                onOk() {
                    dispatch(
                        transactionAction(
                            parseIDfromHash(pk),
                            TransactionAction.reject,
                            matchRecord,
                        ),
                    );
                },
                cancelText: 'Cancel',
                onCancel() {},
            });
        } else {
            dispatch(transactionAction(parseIDfromHash(pk), TransactionAction.reject, matchRecord));
        }
    };

    const componentCallBacks: componentCallBacksModel = {
        showConnectModal: setViewAgreement,
        showCropDetailsModal: setOpenDetailsModal,
        populateCropDetails: setSelectedCropDetails,
        rejectMatch: is_buyer ? rejectBuyerMatch : rejectSellerMatch,
    };

    return (
        <div id={idName}>
            <Title level={2}>{t('Matches.my matches')}</Title>
            <Button
                type="link"
                disabled={reloadClicked === 5}
                style={{ float: 'right' }}
                className="refresh-button"
                onClick={() => {
                    setReloadClicked(reloadClicked + 1);
                    is_buyer ? dispatch(getProduceList()) : dispatch(getAllSellerMatches());
                }}
            >
                <Text style={{ color: '#4285F4' }}>Refresh &nbsp;</Text>
                <img src={Refresh} alt="refresh" />
            </Button>
            {!userState.isMatchesFetching ? (
                <div>
                    {is_buyer ? (
                        <div>
                            {!isEmpty(userState.matchesList) && (
                                <Row className="matchListHeaders">
                                    <Col style={{ textAlign: 'center' }} flex={2}>
                                        Seller Id
                                    </Col>
                                    <Col style={{ textAlign: 'center' }} flex={2}>
                                        Produce
                                    </Col>
                                    <Col style={{ textAlign: 'center' }} flex={1}>
                                        Quantity Matched
                                    </Col>
                                    <Col style={{ textAlign: 'center' }} flex={1}>
                                        Total price
                                    </Col>
                                    <Col style={{ textAlign: 'center' }} flex={1}>
                                        Location
                                    </Col>
                                    <Col style={{ textAlign: 'center' }} flex={7}></Col>
                                </Row>
                            )}
                            {matchListData.map((record: any, openIndex: any) => {
                                const singleBestData: any =
                                    record?.bestMatches[0]?.singleFullfillmentData;
                                const combinedBestData: any =
                                    record?.bestMatches[0]?.combinedParticialData;
                                const childrenData: any = record?.children[0];
                                const singleChildData: any =
                                    record?.children[0]?.singleFullfillmentData;
                                const combinedChildData: any =
                                    record?.children[0]?.combinedParticialData;
                                // console.log(singleChildData, ' singleChildData');
                                // console.log(combinedChildData, 'combinedChildData');
                                return (
                                    <div className="bestMatchesWrapper" key={openIndex}>
                                        <div className="bestMatchesRow">
                                            {!isEmpty(singleBestData) ? (
                                                <SingleFullDataTable
                                                    singleBestData={singleBestData}
                                                    componentCallBacks={componentCallBacks}
                                                />
                                            ) : (
                                                <CombineData
                                                    combinedBestData={combinedBestData}
                                                    componentCallBacks={componentCallBacks}
                                                />
                                            )}
                                        </div>
                                        {!isEmpty(singleChildData) ||
                                        !isEmpty(combinedChildData) ? (
                                            <div
                                                key={openIndex}
                                                id="plusSymbol"
                                                className="moreMatches"
                                                onClick={() => {
                                                    cardOpen === openIndex
                                                        ? setCardOpen(null)
                                                        : setCardOpen(openIndex);
                                                }}
                                            >
                                                <div>
                                                    {cardOpen === openIndex ? (
                                                        <MinusOutlined />
                                                    ) : (
                                                        <PlusOutlined size={28} />
                                                    )}
                                                </div>
                                                <div>
                                                    {cardOpen === openIndex ? (
                                                        <Text>Less Matches</Text>
                                                    ) : (
                                                        <Text>More Matches</Text>
                                                    )}
                                                </div>
                                            </div>
                                        ) : null}
                                        {cardOpen === openIndex && (
                                            <div>
                                                {singleChildData.map((child: any, index: any) => {
                                                    const singleBestData =
                                                        child.singleFullfillmentData;
                                                    if (!isEmpty(singleChildData)) {
                                                        return (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    marginBottom: '20px',
                                                                    marginTop: '-10px',
                                                                }}
                                                            >
                                                                <SingleFullDataTable
                                                                    singleBestData={singleBestData}
                                                                    isChild={true}
                                                                    componentCallBacks={
                                                                        componentCallBacks
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        return <div>No data</div>;
                                                    }
                                                })}
                                                {combinedChildData.map((child: any, index: any) => {
                                                    if (!isEmpty(combinedChildData)) {
                                                        return (
                                                            <div
                                                                key={index}
                                                                style={{
                                                                    marginBottom: '20px',
                                                                    marginTop: '-10px',
                                                                }}
                                                            >
                                                                <CombineData
                                                                    combinedBestData={child}
                                                                    isChild={true}
                                                                    componentCallBacks={
                                                                        componentCallBacks
                                                                    }
                                                                />
                                                            </div>
                                                        );
                                                    } else {
                                                        return <div>No data</div>;
                                                    }
                                                })}
                                            </div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <Table
                            loading={userState.isMatchesFetching}
                            className="margin-t-1em matches-table"
                            columns={matchesSellerColumns(componentCallBacks)}
                            dataSource={userState.matchesList}
                        />
                    )}
                </div>
            ) : (
                <div className="SpinnerMatch">
                    <Spin size="large" />
                </div>
            )}
            <ViewCropDetails
                cropDetails={selectedCropDetails}
                openDetailsModal={openDetailsModal}
                setOpenDetailsModal={setOpenDetailsModal}
            />
            <ConnectMatches
                cropDetails={selectedCropDetails}
                setViewAgreement={setViewAgreement}
                viewAgreement={viewAgreement}
            />
        </div>
    );
};

export default Matches;
