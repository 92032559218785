import React, { useEffect, useState } from 'react';
import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    Modal,
    PageHeader,
    Row,
    Tag,
    Typography,
    Upload,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CaretRightFilled, CheckCircleFilled, LeftOutlined } from '@ant-design/icons';
import { cloneDeep, isEmpty } from 'lodash';
import { History } from 'history';

import './profile.scss';
import {
    fieldLayout,
    initialFormValues,
    kycFlagDetails,
    KycNewDocList,
    checkEmpty,
    fpoSList,
} from './constants';
import BankDocuments from './bankDocuments';
import BuyerWorkingHours from './buyerWorkingHours';
import DocumentsUploaded from './documentsUploaded';
import { emailValidator, generateFormData, validateUpload } from './utils';
import ConfirmationMessage from './confirmationMessage';

import Header from '../header';

import { UserTypes } from '../store/genericTypes';
import { RootState } from '../store/rootReducer';
import CancelBtn from '../app-components/cancelBtn';
import PrimaryBtn from '../app-components/primaryBtn';
import { getUserFiles, saveKyc } from '../store/loginReducer/actions';
import { normFile } from '../app-components/uploadDocument';
import { useTranslation } from 'react-i18next';

const { Title, Text } = Typography;

const Profile = (props: { history: History }) => {
    const { history } = props;
    const dispatch = useDispatch();
    const [changeEmail, setChangeEmail] = useState(false);
    const [isAddClicked, setAddClicked] = useState(false);
    const [isChangeClicked, setChangeClicked] = useState(false);
    const [showProfilePic, setProfilePic] = useState(true);
    const [kycFlag, setKycFlag] = useState('');
    const [showConfirmation, setConfirmation] = useState(false);
    const [showConfirmAccountNumber, setShowConfirmAccountNumber] = useState(false);
    const [isSave, setSaveFlag] = useState(false);
    const [disableSave, setDisableSave] = useState(true);
    const [imageSrc, setImageSrc] = useState();
    const [isPDF, setPDF] = useState(false);
    const [kycFormValues, setKycFormValues] = useState(initialFormValues);
    const [formSubmitValue, setFormSubmitValue] = useState({});
    const [mandatoryFields, setMandatoryFields] = useState(false);
    const [userBankInfo, setUserBankInfo] = useState(false);
    const [showWorkingHourAlert, setShowWorkingHourAlert] = useState(false);
    const [changeWorkingHr, setChangeWorkingHr] = useState(false);
    const { t } = useTranslation('common');

    const loginState = useSelector((state: RootState) => state.loginUser);
    const { bank_info, bank_doc, configs, working_hours, category } = loginState;
    const { pan, uidai, pan_card, aadhaar_card, rtc, rtc_card, kisancard, kisancard_card } =
        loginState;
    const userType = loginState.is_buyer ? UserTypes.BUYER : UserTypes.SELLER;
    const subType = userType === UserTypes.BUYER ? loginState.buyer_type : loginState.seller_type;
    const addBankInfo =
        isEmpty(bank_info?.account_holder_name) &&
        isEmpty(bank_info?.account_no) &&
        isEmpty(bank_info?.ifsc_code) &&
        isEmpty(bank_info?.upi_id);

    useEffect(() => {
        // Do this if the profile is not verified else set kyc flag to completed
        const bankSubmitted =
            !isEmpty(loginState?.bank_info?.account_holder_name) &&
            !isEmpty(loginState?.bank_info?.account_no) &&
            !isEmpty(loginState?.bank_info?.ifsc_code) &&
            !isEmpty(loginState?.bank_doc);

        const aadharSubmitted = !isEmpty(loginState?.uidai) && !isEmpty(loginState?.aadhaar_card);
        const panSubmitted = !isEmpty(loginState?.pan) && !isEmpty(loginState?.pan_card);
        const gstinSubmitted = !isEmpty(loginState?.gstin) && !isEmpty(loginState.gstin_doc);
        const isApproved = loginState.kyc_flag === 'approved' ? true : false;
        const fposSubmitted = !isEmpty(loginState?.fpo);
        const rentalAgreementSubmitted =
            !isEmpty(loginState?.rentalAgreement) && !isEmpty(loginState?.rental_agreement);

        if (userType === UserTypes.BUYER) {
            if (isApproved) {
                setKycFlag('complete');
            } else {
                if (!loginState?.category) {
                    panSubmitted && aadharSubmitted
                        ? setKycFlag('submitted')
                        : setKycFlag('incomplete');
                } else if (loginState?.category === 'FPOs') {
                    if (
                        loginState.urd_status === 'registered' ||
                        loginState.urd_status === 'composite'
                    ) {
                        gstinSubmitted && fposSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    } else {
                        panSubmitted && fposSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    }
                } else if (loginState.category != 'FPOs') {
                    if (
                        loginState.urd_status === 'registered' ||
                        loginState.urd_status === 'composite'
                    ) {
                        gstinSubmitted && rentalAgreementSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    } else {
                        panSubmitted && rentalAgreementSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    }
                }
            }
        } else {
            if (isApproved) {
                setKycFlag('complete');
            } else {
                if (!loginState?.category) {
                    aadharSubmitted && panSubmitted && bankSubmitted
                        ? setKycFlag('submitted')
                        : setKycFlag('incomplete');
                } else if (loginState.category === 'FPOs') {
                    if (
                        loginState.urd_status === 'registered' ||
                        loginState.urd_status === 'composite'
                    ) {
                        gstinSubmitted && fposSubmitted && bankSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    } else {
                        panSubmitted && fposSubmitted && bankSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    }
                } else if (loginState.category != 'FPOs') {
                    if (
                        loginState.urd_status === 'registered' ||
                        loginState.urd_status === 'composite'
                    ) {
                        gstinSubmitted && rentalAgreementSubmitted && bankSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    } else {
                        panSubmitted && rentalAgreementSubmitted && bankSubmitted
                            ? setKycFlag('submitted')
                            : setKycFlag('incomplete');
                    }
                }
            }
        }
    }, [loginState]);

    useEffect(() => {
        if (!isEmpty(loginState.profile_picture)) {
            dispatch(getUserFiles(loginState?.profile_picture?.doc_key, setImageSrc, setPDF));
        }
    }, [loginState]);

    const uploadProfilePic = (event: any) => {
        console.log(event, 'event');
        isEmpty(event.fileList) ? setProfilePic(true) : setProfilePic(false);
        setDisableSave(false);
        console.log(isPDF);
    };

    const setKycToComplete = (values: any) => {
        delete values['profile_picture'];
        delete values['email'];
        delete values['upi_id'];
        if (userType === UserTypes.BUYER) {
            delete values['account_holder_name'];
            delete values['bank_info'];
            delete values['account_no'];
            delete values['ifsc_code'];
            delete values['bank_doc'];
            delete values['weekday'];
            delete values['sunday'];
            delete values['saturday'];
            loginState.category !== 'FPOs' && delete values['fpo'];
        } else {
            delete values['bank_doc'];
            loginState.category !== 'FPOs' && delete values['fpo'];
        }

        var count = 0;
        for (const key in values) {
            values.hasOwnProperty(key) && count++;
        }
        const length = count;
        for (const key in values) {
            if (isEmpty(values[key])) {
                count--;
            }
        }
        if (count === length) {
            return true;
        } else {
            return false;
        }
    };

    const docErrorMessage = () => {
        Modal.error({
            title: 'Document field partially filled',
            content:
                'If you wish to enter the document, kindly enter both value and upload image together!',
        });
    };

    const bankErrorMessage = () => {
        Modal.error({
            title: 'Bank field partially filled',
            content: 'If you wish to enter the bank details, kindly enter all the fields together!',
        });
    };

    const onSave = () => {
        console.log(formSubmitValue, 'formSubmitValue');
        const isSubmitted = setKycToComplete(formSubmitValue);
        console.log(isSubmitted, 'isSubmitted');
        const kyc_flag = isSubmitted ? 'submitted' : 'incomplete';
        if (mandatoryFields) {
            console.log('im here');
            const registerDataPromise = generateFormData(
                cloneDeep({
                    ...kycFormValues,
                    isSubmitted,
                    kyc_flag,
                }),
            );
            registerDataPromise.then((data) => dispatch(saveKyc(data, true)));
        } else if (!loginState.is_buyer && userBankInfo) {
            console.log('im here in second');
            const registerDataPromise = generateFormData(
                cloneDeep({
                    ...kycFormValues,
                    isSubmitted,
                    kyc_flag,
                }),
            );
            registerDataPromise.then((data) => dispatch(saveKyc(data, true)));
        } else {
            console.log('im here in 3rd');
            const registerDataPromise = generateFormData(
                cloneDeep({
                    ...kycFormValues,
                }),
            );
            registerDataPromise.then((data) => dispatch(saveKyc(data, true)));
        }
    };

    const checkForError = (name: any, field: any) => {
        const a = isEmpty(name);
        const b = isEmpty(field);
        return (a && !b) || (!a && b);
    };

    const onFinish = (values: any) => {
        console.log(values, 'values');
        const undefinedKeys = [];
        for (const key in values) {
            if (values.hasOwnProperty(key) && values[key] === undefined) {
                undefinedKeys.push(key);
            }
        }
        // Use the undefined keys to set new values for the properties
        undefinedKeys.forEach((key) => {
            switch (key) {
                case 'account_no':
                    values[key] = bank_info?.account_no;
                case 'account_holder_name':
                    values[key] = bank_info?.account_holder_name;
                case 'bank_doc':
                    values[key] = bank_info?.bank_doc;
                case 'ifsc_code':
                    values[key] = bank_info?.ifsc_code;
                case 'upi_id':
                    values[key] = bank_info?.upi_id;
            }
        });
        console.log('Success:', values);
        setFormSubmitValue(values);
        delete values[''];
        const a = isEmpty(values.account_holder_name);
        const b = isEmpty(values.account_no);
        const c = isEmpty(bank_doc)
            ? isEmpty(values.bank_doc)
            : isEmpty(values.account_holder_name)
            ? true
            : false;
        const d = isEmpty(values.ifsc_code);
        var bankError = !((a && b && c && d) || (!a && !b && !c && !d));

        var panError, rtcError, kisanError, aadhaarError;

        if (isEmpty(kisancard_card) && isEmpty(kisancard)) {
            kisanError = checkForError(values.kisancard, values.kisancard_card);
        }
        if (isEmpty(pan_card) && isEmpty(pan)) {
            panError = checkForError(values.pan, values.pan_card);
        }
        if (isEmpty(rtc_card) && isEmpty(rtc)) {
            rtcError = checkForError(values.rtc, values.rtc_card);
        }
        if (isEmpty(uidai) && isEmpty(aadhaar_card)) {
            aadhaarError = checkForError(values.uidai, values.aadhaar_card);
        }

        const docError = panError || rtcError || kisanError || aadhaarError;

        if (docError) {
            docErrorMessage();
        } else if (bankError) {
            bankErrorMessage();
        } else {
            var formSubmitValues = values;
            formSubmitValues = { ...formSubmitValues };
            console.log('formSubmitValues', formSubmitValues);
            var finalValues: any = {};
            for (const property in formSubmitValues) {
                if (!isEmpty(formSubmitValues[property])) {
                    finalValues = { ...finalValues, [property]: formSubmitValues[property] };
                }
            }
            setKycFormValues(finalValues);
            if (!isEmpty(finalValues)) {
                setSaveFlag(true);
                setConfirmation(true);
            }
        }
    };

    const onFinishFailed = (error: any) => {
        console.log('Failed:', error);
    };

    return (
        <div className="profile-page">
            <div style={{ display: 'none' }}>
                <Header history={history} showActions isLoggedIn />
            </div>
            {(loginState.is_buyer || loginState.is_seller) && (
                <div className="profile-wrapper">
                    <Button
                        type="link"
                        onClick={() => window.history.back()}
                        style={{
                            position: 'relative',
                            top: '1rem',
                            left: '0.5rem',
                            color: '#4E4E4E',
                        }}
                    >
                        <LeftOutlined /> Back to dashboard
                    </Button>

                    <PageHeader
                        backIcon={null}
                        title={t('Profile.My Profile')}
                        tags={kycFlagDetails.map((list, index: number) => {
                            return kycFlag === list.flag ? (
                                <Tag
                                    key={index}
                                    color={list.backgroundColor}
                                    style={{
                                        color: list.color,
                                        fontSize: 'large',
                                        padding: '0.5em',
                                    }}
                                >
                                    {list.title} {list.icon ? <CheckCircleFilled /> : null}
                                </Tag>
                            ) : (
                                <Tag key={index} style={{ display: 'none' }}></Tag>
                            );
                        })}
                    />
                    {/* <Divider className="margin-zero" /> */}
                    <Form
                        labelAlign="left"
                        labelCol={{ span: 24 }}
                        wrapperCol={{ span: 18 }}
                        scrollToFirstError
                        colon={false}
                        name="profile"
                        initialValues={{ loginState }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                    >
                        <Row style={{ paddingLeft: '1em' }}>
                            <Col span={4} style={{ paddingTop: '1em' }}>
                                {/* <Col span={12} style={{ paddingTop: '2em', background: 'aqua' }}></Col> */}
                                <Form.Item
                                    name="profile_picture"
                                    className="profile-picture"
                                    valuePropName="fileList"
                                    getValueFromEvent={normFile}
                                    rules={[
                                        { validator: (rule, value) => validateUpload(rule, value) },
                                    ]}
                                >
                                    <Upload
                                        accept="image/*"
                                        beforeUpload={(file) => {
                                            return false;
                                        }}
                                        name="picture"
                                        listType="picture-card"
                                        maxCount={1}
                                        onChange={uploadProfilePic}
                                    >
                                        {showProfilePic && (
                                            <>
                                                {isEmpty(loginState.profile_picture) ? (
                                                    <Text>Upload Photo</Text>
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: '600px',
                                                            position: 'relative',
                                                            top: '20px',
                                                        }}
                                                    >
                                                        <img
                                                            style={{
                                                                width: '100px',
                                                                height: '100px',
                                                            }}
                                                            src={imageSrc}
                                                        />
                                                        <Text style={{ width: '500px' }}>
                                                            click to change
                                                        </Text>
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </Upload>
                                </Form.Item>
                                {/* <Divider className="margin-zero" /> */}
                                {/* <div
                                    className={
                                        kycFlag === 'incomplete'
                                            ? `add-details-text`
                                            : `display-none`
                                    }
                                >
                                    <Text>
                                        Add details of fields with{' '}
                                        <CaretRightFilled style={{ color: '#FF9900' }} /> to
                                        complete KYC
                                    </Text>
                                </div> */}
                                {/* <Col span={12} style={{ paddingTop: '2em', background: 'white' }}> */}
                                {/* <Form.Item>
                                    {(loginState.is_buyer || loginState.is_seller) && (
                                        <DocumentsUploaded
                                            config={configs}
                                            kycFlag={kycFlag}
                                            userDetails={loginState}
                                            setDisableSave={setDisableSave}
                                            setMandatoryFields={setMandatoryFields}
                                        />
                                    )}
                                    {userType === UserTypes.BUYER && (
                                        <BuyerWorkingHours
                                            workingHours={working_hours}
                                            setDisableSave={setDisableSave}
                                            setChangeWorkingHr={setChangeWorkingHr}
                                        />
                                    )}

                                </Form.Item> */}
                            </Col>
                            {/* <Col span={14} style={{ paddingTop: '2em', background: 'red' }}> */}
                            <Col
                                span={10}
                                offset={6}
                                style={{ paddingTop: '1em' }}
                                className="kyc-form-elements"
                            >
                                <Form.Item
                                    {...fieldLayout}
                                    label={
                                        <span className="kyc-form-label">{t('Profile.name')}</span>
                                    }
                                    className="margin-zero"
                                >
                                    : {loginState.name}
                                </Form.Item>

                                <Form.Item
                                    {...fieldLayout}
                                    label={
                                        <span className="kyc-form-label">
                                            {t('Profile.user type')}
                                        </span>
                                    }
                                    className="margin-zero"
                                >
                                    : {userType}
                                </Form.Item>
                                {loginState.urd_status ? (
                                    <Form.Item
                                        {...fieldLayout}
                                        label={
                                            <span className="kyc-form-label">
                                                {t('Profile.dealer type')}
                                            </span>
                                        }
                                        className="margin-zero"
                                    >
                                        :{loginState.urd_status}
                                    </Form.Item>
                                ) : null}
                                <Form.Item
                                    {...fieldLayout}
                                    label={
                                        <span className="kyc-form-label">
                                            {t(`kyc.${userType}`)}
                                        </span>
                                    }
                                    className="margin-zero"
                                >
                                    <Text style={{ textTransform: 'capitalize' }}>: {subType}</Text>
                                </Form.Item>
                                {!isEmpty(category) ? (
                                    <Form.Item
                                        {...fieldLayout}
                                        label={
                                            <span className="kyc-form-label">
                                                {t(`Profile.${userType}`)}
                                            </span>
                                        }
                                        className="margin-zero"
                                    >
                                        <Text style={{ textTransform: 'capitalize' }}>
                                            : {category}
                                        </Text>
                                    </Form.Item>
                                ) : null}
                                <Form.Item
                                    {...fieldLayout}
                                    label={
                                        <span className="kyc-form-label">
                                            {t('Profile.address1')}
                                        </span>
                                    }
                                    className="margin-zero"
                                >
                                    : {loginState.address1}
                                    <br />
                                    {loginState.zip}
                                    <br />
                                </Form.Item>
                                {/* <Form.Item
                                    {...fieldLayout}
                                    label={
                                        <span className="kyc-form-label">
                                            {t('Profile.address2')}
                                        </span>
                                    }
                                    className="margin-zero"
                                >
                                    : {loginState.address}
                                </Form.Item> */}

                                {(loginState.is_buyer || loginState.is_seller) && (
                                    <DocumentsUploaded
                                        config={configs}
                                        kycFlag={kycFlag}
                                        userDetails={loginState}
                                        setDisableSave={setDisableSave}
                                        setMandatoryFields={setMandatoryFields}
                                    />
                                )}
                            </Col>
                            <Col
                                span={9}
                                style={{
                                    paddingTop: '1em',
                                    paddingLeft: '1em',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div>
                                    <Form.Item
                                        {...fieldLayout}
                                        label={
                                            <span className="kyc-form-label">
                                                {t('Profile.phone number')}
                                            </span>
                                        }
                                        className="margin-zero"
                                    >
                                        : {loginState.phone_no}
                                    </Form.Item>
                                    <Form.Item
                                        {...fieldLayout}
                                        label={
                                            <span className="kyc-form-label">
                                                {t('Profile.email')}
                                            </span>
                                        }
                                        className="margin-zero"
                                    >
                                        {changeEmail || isEmpty(loginState.email) ? (
                                            <React.Fragment>
                                                <Form.Item
                                                    className="margin-zero float-left"
                                                    name={
                                                        isEmpty(loginState.email) || changeEmail
                                                            ? 'email'
                                                            : undefined
                                                    }
                                                    rules={[
                                                        {
                                                            validator: (rule, value) =>
                                                                emailValidator(rule, value),
                                                        },
                                                    ]}
                                                >
                                                    <Input
                                                        className={
                                                            changeEmail
                                                                ? `custom-input kyc-input-field width-85`
                                                                : `custom-input kyc-input-field`
                                                        }
                                                        defaultValue={loginState.email}
                                                        onChange={() => setDisableSave(false)}
                                                        contentEditable
                                                    />
                                                </Form.Item>
                                                {changeEmail && (
                                                    <Button
                                                        type="link"
                                                        danger
                                                        onClick={() => {
                                                            setChangeEmail(false);
                                                        }}
                                                    >
                                                        {t('Register.content4')}
                                                    </Button>
                                                )}
                                            </React.Fragment>
                                        ) : (
                                            <Text>
                                                :&nbsp;{loginState.email}
                                                <Button
                                                    type="link"
                                                    className="email-change "
                                                    onClick={() => setChangeEmail(true)}
                                                >
                                                    {t('Register.Change')}
                                                </Button>
                                            </Text>
                                        )}
                                    </Form.Item>
                                </div>
                                <div>
                                    <div className="add-details-text add-bank-details">
                                        <Title
                                            level={5}
                                            style={{ width: 'fit-content', float: 'left' }}
                                        >
                                            {t('Profile.bank details')}
                                        </Title>
                                        {addBankInfo ? (
                                            <Button
                                                type="link"
                                                style={{ marginLeft: '6em' }}
                                                onClick={() => {
                                                    setAddClicked(!isAddClicked);
                                                    userBankInfo
                                                        ? setUserBankInfo(false)
                                                        : setUserBankInfo(true);
                                                }}
                                            >
                                                {isAddClicked ? (
                                                    <Text type="danger">
                                                        {t('Register.content4')}
                                                    </Text>
                                                ) : (
                                                    <Text>{t('Register.Add')}</Text>
                                                )}
                                            </Button>
                                        ) : (
                                            <Button
                                                type="link"
                                                style={{ marginLeft: '6em' }}
                                                onClick={() => {
                                                    setChangeClicked(!isChangeClicked);
                                                    setShowConfirmAccountNumber(false);
                                                    userBankInfo
                                                        ? setUserBankInfo(false)
                                                        : setUserBankInfo(true);
                                                }}
                                            >
                                                {isChangeClicked ? (
                                                    <Text type="danger">
                                                        {t('Register.content4')}
                                                    </Text>
                                                ) : (
                                                    <Text>{t('Register.Change')}</Text>
                                                )}
                                            </Button>
                                        )}
                                    </div>
                                    <BankDocuments
                                        bankInfo={bank_info}
                                        bank_doc={bank_doc}
                                        userType={userType}
                                        kycFlag={kycFlag}
                                        isAddClicked={isAddClicked}
                                        isChangedClicked={isChangeClicked}
                                        setDisableSave={setDisableSave}
                                        showConfirmAccountNumber={showConfirmAccountNumber}
                                        setShowConfirmAccountNumber={setShowConfirmAccountNumber}
                                    />
                                </div>
                            </Col>
                            {/* </Col> */}
                            {/* {kycFlag === 'incomplete' && (
                            <Col>
                                <div className="kyc-required-doc-list">
                                    <Title level={4}>Add following details to complete KYC</Title>
                                    {loginState.category === 'FPOs' ? (
                                        <ul>
                                            {fpoSList.map((list, index: number) => {
                                                return (
                                                    <React.Fragment key={index}>
                                                        {list.userType === userType &&
                                                            list.subType === subType &&
                                                            list.urd_status ===
                                                                loginState.urd_status && (
                                                                <>
                                                                    {checkEmpty(
                                                                        list.title,
                                                                        loginState,
                                                                    )}
                                                                </>
                                                            )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </ul>
                                    ) : (
                                        <ul>
                                            {KycNewDocList.map((list) => {
                                                return (
                                                    <React.Fragment>
                                                        {list.userType === userType &&
                                                            list.subType === subType &&
                                                            list.urd_status ===
                                                                loginState.urd_status && (
                                                                <>
                                                                    {checkEmpty(
                                                                        list.title,
                                                                        loginState,
                                                                    )}
                                                                </>
                                                            )}
                                                    </React.Fragment>
                                                );
                                            })}
                                        </ul>
                                    )}
                                </div>
                            </Col>
                        )} */}
                        </Row>
                        <Row style={{ paddingLeft: '40em' }}>
                            <PrimaryBtn
                                disabled={disableSave}
                                htmlType="submit"
                                style={{ margin: '2em', width: '12%' }}
                                // style={{ width: '100%' }}
                                content={t('Register.Save')}
                            />
                            <CancelBtn
                                onClick={() => {
                                    setConfirmation(true);
                                    setSaveFlag(false);
                                }}
                                style={{ margin: '2em', width: '12%' }}
                            />
                        </Row>
                    </Form>

                    <ConfirmationMessage
                        showConfirmation={showConfirmation}
                        setConfirmation={setConfirmation}
                        response={loginState}
                        isSave={isSave}
                        disableSave={disableSave}
                        onConfirm={isSave ? onSave : () => {}}
                    />
                    <Modal
                        open={showWorkingHourAlert}
                        cancelButtonProps={{ style: { display: 'none' } }}
                        onOk={(event: any) => {
                            setShowWorkingHourAlert(false);
                        }}
                    >
                        You cannot select all Three days as a holiday
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Profile;
