import React, { useState } from 'react';
import { Breadcrumb, Button, Dropdown, Image, Menu, Modal, Space } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import { History } from 'history';

import './header.scss';
import MobileDrawer from './mobileDrawer';

import Register from '../login-ui/register';
import UserHeader from '../header-profile';
import Logo from '../static/assets/vbLogo.png';
import KannadaLogo from '../static/assets/kannadaLogo.png';
import PrimaryBtn from '../app-components/primaryBtn';
import DefaultBtn from '../app-components/defaultBtn';
import { isEnglish } from '../static/translations/constants';
import LoginPopup from '../login-ui/login-popup';

const UserBasicActions = ({ history, popUpTrigger }: { history: History; popUpTrigger: any }) => {
    const { signUpPopupVisible, setSignUpPopupVisible } = popUpTrigger;
    const { t } = useTranslation('common');
    const [showLogin, setLogin] = useState(false);

    const languages = [
        {
            code: 'en',
            name: 'English',
            country_code: 'gb',
        },
        {
            code: 'kn',
            name: 'Kannada',
            country_code: 'in',
        },
        {
            code: 'ta',
            name: 'Tamil',
            country_code: 'tn', //add correct country-code
        },
    ];

    return (
        <div className="display-flex-row align-center action-buttons">
            <DefaultBtn
                className="vikas-btn-radius custom-login-button"
                content={t('landing_page.actions.login')}
                onClick={() => setLogin(true)}
                size="large"
            />
            <PrimaryBtn
                id="header-register-button"
                className="margin-l-r-1em vikas-btn-radius wid150 custom-register-button"
                type="primary"
                size="large"
                onClick={() => setSignUpPopupVisible(!signUpPopupVisible)}
                content={t('landing_page.actions.register')}
            />
            <div
                style={{
                    display: 'flex',
                    width: '100px',
                    height: '50px',
                    padding: 30,
                    border: '0px none',
                }}
            >
                <>
                    <Dropdown
                        overlay={
                            <ul className="dropDownLang">
                                {languages.map(({ code, name, country_code }) => {
                                    return (
                                        <li className="listStylesLang" key={country_code}>
                                            <button onClick={() => i18next.changeLanguage(code)}>
                                                {name}
                                            </button>
                                        </li>
                                    );
                                })}
                            </ul>
                        }
                        trigger={['click']}
                    >
                        <Button className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
                            {t('landing_page.header.breadcrumb.language')}
                            <GlobalOutlined />
                        </Button>
                    </Dropdown>
                </>
            </div>
            <Modal
                title={null}
                open={signUpPopupVisible}
                footer={null}
                closable={false}
                maskClosable={false}
                className="custom-register-modal"
                onCancel={() => setSignUpPopupVisible(!signUpPopupVisible)}
                centered
                wrapClassName="register-popup-container"
            >
                <Register history={history} setSignUpPopupVisible={setSignUpPopupVisible} />
            </Modal>
            <Modal
                title={null}
                open={showLogin}
                footer={null}
                width={'30%'}
                maskClosable={false}
                className="custom-login-modal"
                centered
                closable={false}
                wrapClassName="login-popup-container"
            >
                <LoginPopup history={history} setLogin={setLogin} />
            </Modal>
        </div>
    );
};

const HeaderActions = ({
    history,
    isLoggedIn,
    popUpTrigger,
}: {
    history: History;
    isLoggedIn: boolean;
    popUpTrigger: any;
}) => {
    return isLoggedIn ? (
        <UserHeader history={history} />
    ) : (
        <UserBasicActions history={history} popUpTrigger={popUpTrigger} />
    );
};

const Header = (headerProps: any) => {
    const { t } = useTranslation('common');
    const { history, showActions, isLoggedIn, popUpTrigger } = headerProps;
    const logo = isEnglish(t('language')) ? Logo : KannadaLogo;
    const menu = (
        <Menu>
            <a href="#ecosystem" rel="noopener noreferrer">
                <Menu.Item>Technology Differentiaters</Menu.Item>
            </a>
            <a href="#howVikasbandhuworks" rel="noopener noreferrer">
                <Menu.Item>How vikasBandhu Works</Menu.Item>
            </a>
            <a href="#BestPracticesContainer" rel="noopener noreferrer">
                <Menu.Item>Best Practises</Menu.Item>
            </a>
        </Menu>
    );

    return (
        <div className="landing-page-header-bar custom-height-alignment">
            <div className="landing-page-header-wrapper">
                <Image className="logo" src={logo} preview={false} />
                <Breadcrumb className="header-breadcrumb" separator="|">
                    <Breadcrumb.Item href="#home">
                        {t('landing_page.header.breadcrumb.home')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="#aim">
                        {t('landing_page.header.breadcrumb.aim')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item overlay={menu}>
                        <a href="#ecosystem">{t('landing_page.header.breadcrumb.solution')}</a>
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item href='#ecosystem'>{t('landing_page.header.breadcrumb.ecosystem')}</Breadcrumb.Item> */}
                    <Breadcrumb.Item href="#commodities">
                        {t('landing_page.header.breadcrumb.commodities')}
                    </Breadcrumb.Item>
                    {/* <Breadcrumb.Item href='#users'>{t('landing_page.header.breadcrumb.users')}</Breadcrumb.Item> */}
                    {/* <Breadcrumb.Item href='#aboutUs'>{t('landing_page.header.breadcrumb.about_us')}</Breadcrumb.Item> */}
                    <Breadcrumb.Item href="#team">
                        {t('landing_page.header.breadcrumb.team')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="#legal">
                        {t('landing_page.header.breadcrumb.legal')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="#contactUs">
                        {t('landing_page.header.breadcrumb.contact_us')}
                    </Breadcrumb.Item>
                    <Breadcrumb.Item href="#updates">
                        {t('landing_page.header.breadcrumb.updates')}
                    </Breadcrumb.Item>
                </Breadcrumb>
                <MobileDrawer />
                {showActions ? (
                    <HeaderActions
                        isLoggedIn={isLoggedIn}
                        history={history}
                        popUpTrigger={popUpTrigger}
                    />
                ) : null}
            </div>
        </div>
    );
};

export default Header;
