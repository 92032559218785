import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Alert, Spin } from 'antd';

import { deviceIdPayload, getUserCompleteDetails, getUserDetails } from '../store/loginReducer/actions';
import { RootState } from '../store/rootReducer';
import { UserStateModel } from '../store/loginReducer/types';
import { routesMap } from '../constants';
import moment from 'moment';
import { isEmpty } from 'lodash';

const { buyer_ui, seller_ui } = routesMap;

const ValidateUserAuthentication = (props: any) => {
    const { history } = props;
    const loginState: UserStateModel = useSelector((state: RootState) => state.loginUser);
    const { signInState, is_seller, is_buyer, amplifyResponse, phone_no } = loginState;
    const dispatch = useDispatch();
    const date = new Date();
    console.log(date, 'date');
    const custmDate = moment(date).format('MM/DD/YYYY');
    console.log(custmDate, 'custmDate');
    console.log(loginState, 'loginState');
    console.log(loginState.phone_no, 'loginState phone_no')

    useEffect(() => {
        dispatch(getUserDetails());
    }, []);

    useEffect(() => {
        if (signInState.isVerified && amplifyResponse) {
            if (is_seller) {
                history.push(seller_ui);
            } else if (is_buyer) {
                history.push(buyer_ui);
            }
        }
    }, [signInState.isVerified, amplifyResponse]);

    useEffect(() => {
        if(loginState?.phone_no){
            const data = {
                phone_no: loginState?.phone_no,
                device_token: {
                    deviceToken: (window as any).deviceId,
                    date: custmDate,
                },
            };
            console.log((window as any).deviceId, 'deviceId');
            console.log(data,'data')
            if(!isEmpty(data.device_token['deviceToken'])){
                dispatch(deviceIdPayload(data));
            }else{
                alert('deviceToken data is broken')
                console.log('device Token is empty')
            }
        }
    }, [loginState?.phone_no]);

    return (
        <Spin spinning={!signInState.hasError}>
            <Alert
                className="text-align-center"
                banner
                message={
                    signInState.hasError
                        ? signInState.msg
                        : 'Please wait while we are validating the user'
                }
                description={
                    signInState.hasError
                        ? signInState.msg
                        : 'Please wait while we are validating the user'
                }
                type={signInState.hasError ? 'error' : 'info'}
            />
        </Spin>
    );
};

export default ValidateUserAuthentication;
