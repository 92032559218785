import React, { useEffect, useState } from 'react';
import {
    Alert,
    Col,
    Divider,
    Modal,
    Row,
    Space,
    Statistic,
    Typography
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';

import { confirmOTP, resendOTP } from '../../store/registrationReducer/actions';
import { RootState } from '../../store/rootReducer';
import PrimaryBtn from '../../app-components/primaryBtn';
import InputOtp from '../../app-components/inputOtp';

import './register.scss';
import { maskData } from '../../app-components/utils';
import Registration from '../registration';

const { Text, Title } = Typography;
const { Countdown } = Statistic;

interface ConfirmOTPProps {
    showOTPModal: boolean;
    setShowOTPModal: Function;
    currentType: string;
    history: History;
};

const ConfirmOTPModal = ({ showOTPModal, setShowOTPModal, currentType, history }: ConfirmOTPProps) => {
    const dispatch = useDispatch();
    const registrationState = useSelector((state: RootState) => state.registration);

    const { otpError, formData } = registrationState;
    const phoneNumber = '' + formData?.phone_no;
    const maskedNumber = maskData(phoneNumber);
    const [otpTimer, setOtpTimer] = useState(0);
    const [resend, showResend] = useState(false);
    const [showKycOne,setShowKycOne] = useState(false);
    const [otpResent, setOtpResent] = useState(false);
    const [otp, setOtp] = useState('');

    useEffect(() => {
        if (otpError.verified) {
            setShowOTPModal(!showOTPModal);
            setShowKycOne(true);
            // return history.push(`register/${currentType.toLocaleLowerCase()}`);
        }
    }, [otpError.verified]);

    useEffect(() => {
        if (showOTPModal) {
            setOtpTimer(Date.now() + 1000 * 60);
        }
    }, [showOTPModal]);

    const retryOtpSend = () => {
        setOtpResent(true);
        dispatch(resendOTP());
    };

    const submitOtp=()=>{
        dispatch(confirmOTP(formData?.phone_no, otp))
    }


    return (
        <div>
            {showKycOne ? <Registration history={history} showKycOne={showKycOne} setShowKycOne={setShowKycOne} />:null}
        <Modal
            wrapClassName='otp-modal'
            className='custom-otp-modal'
            title={<Title level={5}>OTP Verification</Title>}
            centered
            closable={true}
            maskClosable={false}
            open={showOTPModal}
            footer={null}
            onCancel={() => setShowOTPModal(!showOTPModal)}
        >
            <Row justify='center'>
                <Col>
                    <Text>
                        Please enter 4 digit OTP number sent to your phone number +91-{maskedNumber}
                    </Text>
                </Col>
                <Col>
                    <InputOtp setInput={setOtp} />
                </Col>
            </Row>
            <Row>
                <Space>
                    <Text>Didn't receive OTP?</Text>
                    {!resend ?
                        <React.Fragment>
                            <Text className='custom-color-change'> Resend Code in </Text>
                            <Countdown
                                className='custom-color-change'
                                value={otpTimer} format='mm:ss'
                                onFinish={() => showResend(true)}
                            />
                        </React.Fragment> :
                        !otpResent ?
                            <PrimaryBtn className='add-margin-bottom' onClick={retryOtpSend} content='Resend OTP' /> : null
                    }
                </Space>
                <Divider className='confirm-otp-divider' />
                <Alert
                    className='confirm-otp-modal-warning'
                    type='warning'
                    message='By entering the OTP you accept the terms and conditions 
                        and are ready to verify your profile'
                />
            </Row>
            {otpError.showError &&
                <Row className='margin-t-1em'>
                    <Col span='24'>
                        <Alert message={otpError.errorMg} type='error' showIcon />
                    </Col>
                </Row>
            }
            <Row justify='center' className='margin-t-1em'>
                <Col>
                    <Space>
                        <PrimaryBtn
                            disabled={otp.length !== 4}
                            onClick={() => submitOtp()}
                            content='Submit OTP'
                        />
                    </Space>
                </Col>
            </Row>
        </Modal>
        </div>
    );
};

export default ConfirmOTPModal;
