import React, { useState, useEffect } from 'react';
import {
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    Modal,
    Row,
    Select,
    Divider,
    Typography
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import { addNewProduce } from '../../../store/buyerReducer/actions';
import CancelBtn from '../../../app-components/cancelBtn';
import { MasterListApiFormat, ProduceModel } from '../../../store/buyerReducer/types';
import { RootState } from '../../../store/rootReducer';
import { validateQuantity } from '../../../buyer-seller-commons/produce/utils';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const singleLabelFieldLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 20 },
};

const fieldwithInfoLayout = {
    labelCol: { span: 24 },
    wrapperCol: { span: 18 },
};

type AddCropModalProps = {
    masterProduceList: Array<MasterListApiFormat>;
    isEdit: boolean;
    currentProduceRecord: ProduceModel;
    setModalVisible: Function;
    modalVisible: boolean;
};

const getMasterProduceListOpts = ({ masterProduceList }: { masterProduceList: Array<MasterListApiFormat> }) => {

    return (
        <React.Fragment>
            {masterProduceList.map((masterProduceItem: MasterListApiFormat) => {
                const { category_name = '', produce_name = '', variety_name = '',  grade_name = '' } = masterProduceItem;

                return (
                    <Option
                        key={`${category_name}-${produce_name}-${variety_name}-${grade_name}`}
                        value={`${category_name}-${produce_name}-${variety_name}-${grade_name}`}
                    >
                        {`${category_name}-${produce_name}-${variety_name}-${grade_name}`}
                    </Option>
                );
            })
            }
        </React.Fragment>
    );
};

const AddCropModal = ({
    masterProduceList,
    isEdit,
    currentProduceRecord,
    setModalVisible,
    modalVisible
}: AddCropModalProps) => {
    const [form] = Form.useForm();
    const dispatch = useDispatch();
    const defaultDateStart = new Date();
    const defaultDateEnd = new Date();
    defaultDateStart.setDate(defaultDateStart.getDate() + 4);
    defaultDateEnd.setDate(defaultDateEnd.getDate() + 20);
    const [formInitialize, setFormInitValues] =
        useState({ produce_name: '', quantity: '', delivery_by: '', additional_info: '' });

    const buyerState = useSelector((state: RootState) => state.buyer);
    const loginState = useSelector((state: RootState) => state.loginUser);
    const { produceList } = buyerState;
    const { t } = useTranslation('common');
    
    useEffect(() => {
        if (modalVisible) {
            const formInitValues: any = isEdit ?
                processOnEditInitValues(currentProduceRecord) :
                { produce_name: '', quantity: '', delivery_by: '', additional_info: '' };

            setFormInitValues(formInitValues);
            form.setFieldsValue(formInitValues)
        }
    }, [modalVisible]);

    const onFinish = (fieldsValue: any) => {
        const {
            produce_name,
            // delivery_by, 
            quantity
        } = fieldsValue;
        const [category, produce, variety, grade] = produce_name.split('-');
        
        // const deliveryByIsoformat = new Date(delivery_by).toISOString();
        const additional_info = {
            moisture: fieldsValue.moisture,
            other_info: fieldsValue.other_info,
            packing_size: fieldsValue.packing_size,
            packing_type: fieldsValue.packing_type,
            fungus: fieldsValue.fungus
        };

        const addProducePayload = {
            category: category.trim(),
            produce: produce.trim(),
            variety: variety.trim(),
            grade: grade.trim(),
            // delivery_by: deliveryByIsoformat,
            additional_info,
            isEditable: true,
            quantity: quantity,
            urd_status: loginState.urd_status
        };
        const produceName = `${addProducePayload.category}-${addProducePayload.produce}-${addProducePayload.variety}-${addProducePayload.grade}`;

        let counter = 0;
        for (let i = 0; i < produceList.length; i++) {
            const produceListName = `${produceList[i].category}-${produceList[i].produce}-${produceList[i].variety}-${produceList[i].grade}`;
            if (produceListName === produceName) {
                counter++;
            }
        }
        if (counter < 2) {
            dispatch(addNewProduce(addProducePayload));
            form.resetFields();
            setModalVisible(false);
        } else {
            Modal.error({
                title: "You can't add a requirement more than two times!",
                content: "Please wait till the requirement is fulfilled to add the same requirement!"
            })
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const onReset = () => {
        form.resetFields();
        setModalVisible(false);
    };

    const processOnEditInitValues = (currentProduceRecord: ProduceModel) => {
        const { produce, category, variety, grade } = currentProduceRecord;
        const produce_name = `${category}-${produce}-${variety}-${grade}`;
        const deliveryByProcessed = moment(currentProduceRecord.delivery_by);
        return { ...currentProduceRecord, delivery_by: deliveryByProcessed, produce_name };
    };

    const disabledDate = (currentDate: Object) => {
        return currentDate < moment(defaultDateStart, 'YYYY-MM-DD') || currentDate > moment(defaultDateEnd, 'YYYY-MM-DD');
    };

    return (
        <Modal
            title={t('AddCropModal.add interested crops')}
            open={modalVisible}
            footer={null}
            maskClosable={false}
            onCancel={() => setModalVisible(false)}
            width={'90%'}
            wrapClassName="add-crop-modal"
        >
            <Form
                form={form}
                className="add-crop-form"
                {...singleLabelFieldLayout}
                name="basic"
                initialValues={formInitialize}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Row gutter={16}>
                    <Col xs={24} md={10} lg={10}>
                        <Form.Item
                            label={t('AddCropModal.label')}
                            name="produce_name"
                            rules={[{ required: true, message: 'Please select the Produce!' }]}
                        >
                            <Select className="custom-select" placeholder="Select">
                                {getMasterProduceListOpts({ masterProduceList })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            {...fieldwithInfoLayout}
                            label={t('AddCropModal.label1')}
                            name="quantity"
                            rules={[{
                                required: true,
                                validator: (rules, value) => validateQuantity(rules, value)
                            }]}
                        >
                            <Form.Item name="quantity">
                                <Input
                                    style={{ width: 160 }}
                                    className="custom-input"
                                    placeholder="In quintal"
                                    suffix="Qtl"
                                />
                            </Form.Item>
                        </Form.Item>
                        <Form.Item
                            label={t('AddCropModal.label2')}
                            name="delivery_by"
                        // rules={[{ type: 'object', required: true, message: 'Please select time!' }]}
                        >
                            <DatePicker
                                disabled={true}
                                className="custom-input"
                                format="YYYY-MM-DD"
                                defaultValue={moment(defaultDateStart, 'YYYY-MM-DD')}
                                disabledDate={disabledDate}
                            />
                        </Form.Item>
                    </Col>
                    <Divider className="height-full" type="vertical" style={{ height: "25rem", color: "black" }} />
                    <Col span={12}>
                        <Form.Item label={<Text style={{ fontWeight: 700 }} >{t('AddCropModal.specs1')}</Text>}>
                            <Form.Item
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 12 }}
                                labelAlign="left"
                                label={t('AddCropModal.moist')}
                                name="moisture"
                            >
                                <Input
                                    className="custom-input"
                                    placeholder="Moisture in %"
                                />
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 12 }}
                                labelAlign="left"
                                label={t('AddCropModal.fungus')}
                                name="fungus"
                            >
                                <Input
                                    className="custom-input"
                                    placeholder="Fungus in %"
                                />
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 12 }}
                                labelAlign="left"
                                label={t('AddCropModal.pkg_type')}
                                name="packing_type"
                            >
                                <Input
                                    className="custom-input"
                                    placeholder="Packing type"
                                />
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 12 }}
                                labelAlign="left"
                                label={t('AddCropModal.pkg_size')}
                                name="packing_size"
                            >
                                <Input
                                    className="custom-input"
                                    placeholder="Packing size in kg"
                                />
                            </Form.Item>
                            <Form.Item
                                labelCol={{ span: 10 }}
                                wrapperCol={{ span: 12 }}
                                labelAlign="left"
                                label={t('AddCropModal.info')}
                                name="other_info"
                            >
                                <TextArea className="custom-input" rows={4} />
                            </Form.Item>
                        </Form.Item>
                    </Col>
                </Row>
                <Row justify="center">
                    <Col>
                        <CancelBtn
                            className="margin-l-r-1em crop-modal-action-btn vikas-btn-radius"
                            onClick={onReset}
                        />
                        <Button
                            className="crop-modal-action-btn vikas-btn-radius add-edit-button"
                            type="primary"
                            htmlType="submit"
                        >
                           {t('AddCropModal.add requirements')}
                        </Button>
                    </Col>
                </Row>
            </Form>
        </Modal>
    );
};

export default AddCropModal;
