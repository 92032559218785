import React, { useState } from 'react';
import { Button, Checkbox, Col, Divider, Form, Input, Row, Select, Typography } from 'antd';
import { uniqBy } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { History } from 'history';

import ConfirmOTPModal from './confirmOTPModal';
import { emailRequired, validatePhoneNumber, validateUserName } from './utils';

import {
    customConsentValidator,
    registerBasicFormMainLayout,
    registerBasicFormTailLayout,
} from '../constants';

import { RootState } from '../../store/rootReducer';
import {
    sendOTP,
    updateBasicRegistrationData,
    updateEntityType,
} from '../../store/registrationReducer/actions';
import { UserTypes } from '../../store/genericTypes';
import DefaultBtn from '../../app-components/defaultBtn';
import PrimaryBtn from '../../app-components/primaryBtn';
import { TAndCPopup } from '../../terms-and-conditions/index';
import { useTranslation } from 'react-i18next';

const { Option } = Select;
const { Title } = Typography;

const getUserTypeOption = (configs: any, currentType: string) => {
    const filterUserTypeOptns = uniqBy(
        configs.filter((config: any) => config.type === currentType),
        'sub_type',
    );

    return filterUserTypeOptns.map((userSubType: any) => {
        const { sub_type } = userSubType;
        return <Option value={sub_type}>{sub_type}</Option>;
    });
};

const getUserCategoryOption = (config: any, currentType: string, type: string) => {
    const filterUserSubTypeOptns = uniqBy(
        config.filter((config: any) => config.type === currentType && config.sub_type === type),
        'category',
    );

    return filterUserSubTypeOptns.map((categoryType: any) => {
        const { category } = categoryType;
        return <Option value={category}>{category}</Option>;
    });
};

const Register = ({
    history,
    setSignUpPopupVisible,
}: {
    history: History;
    setSignUpPopupVisible: Function;
}) => {
    const [currentType, setCurrentType] = useState('Buyer');
    const [subType, setSubType] = useState('');
    const [showOTPModal, setShowOTPModal] = useState(false);
    const [displayTandC, setTandC] = useState(false);
    const [showCategory, setShowCategory] = useState(false);
    const { t } = useTranslation('common');

    const dispatch = useDispatch();
    const registrationState = useSelector((state: RootState) => state.registration);
    const { configs } = registrationState;
    const [form] = Form.useForm();

    const onFinish = (values: any) => {
        console.log('values', values);
        const { name, phone_no, email, type, category, urd_status } = values;
        dispatch(sendOTP(`91${phone_no}`));
        dispatch(updateEntityType(currentType));
        dispatch(
            updateBasicRegistrationData({ name, phone_no, email, type, category, urd_status }),
        );
        setSignUpPopupVisible(false);
        setShowOTPModal(!showOTPModal);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log('Failed:', errorInfo);
    };

    const setUserType = (userType: string) => setCurrentType(userType);

    const onSelectType = (type: any, currentType: string) => {
        setSubType(type);
        const filter = uniqBy(
            configs.filter(
                (config: any) => config.type === currentType && config.sub_type === type,
            ),
            'category',
        );
        filter.length > 1 ? setShowCategory(true) : setShowCategory(false);
    };

    return (
        <React.Fragment>
            <ConfirmOTPModal
                history={history}
                setShowOTPModal={setShowOTPModal}
                showOTPModal={showOTPModal}
                currentType={currentType}
            />
            <Title level={4} type="secondary">
                {t('Register.title')}
            </Title>
            <Divider />
            <p className="">{t('Register.title1')}</p>
            <Row gutter={16}>
                <Col span={12}>
                    <DefaultBtn
                        onClick={() => setUserType(UserTypes.SELLER)}
                        size={'large'}
                        className={`width-full ${
                            currentType === UserTypes.SELLER ? 'color-green-shade' : null
                        }`}
                        content={t('Register.content1')}
                    />
                </Col>
                <Col span={12}>
                    <DefaultBtn
                        onClick={() => setUserType(UserTypes.BUYER)}
                        size={'large'}
                        className={`width-full ${
                            currentType === UserTypes.BUYER ? 'color-green-shade' : null
                        }`}
                        content={t('Register.content2')}
                    />
                </Col>
            </Row>
            <Form
                {...registerBasicFormMainLayout}
                form={form}
                name="basic"
                className="register-basic-form"
                initialValues={{}}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
            >
                <Form.Item
                    name="type"
                    label={t('Register.label1')}
                    rules={[{ required: true, message: `Please select ${currentType} type` }]}
                >
                    <Select
                        className="custom-select"
                        placeholder={`Select ${currentType} type`}
                        onSelect={(type: any) => onSelectType(type, currentType)}
                        allowClear
                    >
                        {getUserTypeOption(configs, currentType)}
                    </Select>
                </Form.Item>
                {showCategory ? (
                    <Form.Item
                        label={t('Register.label2')}
                        name="category"
                        rules={[
                            {
                                required: true,
                                message: `Select ${subType} category`,
                            },
                        ]}
                    >
                        <Select
                            className="custom-select"
                            placeholder={`Select ${subType} category`}
                            allowClear
                        >
                            {getUserCategoryOption(configs, currentType, subType)}
                        </Select>
                    </Form.Item>
                ) : null}

                <Form.Item
                    label={t('Register.label3')}
                    name="name"
                    rules={[
                        {
                            required: true,
                            validator: (rule, value) => validateUserName(rule, value),
                        },
                    ]}
                >
                    <Input className="custom-input" />
                </Form.Item>
                <Form.Item
                    label={t('Register.label4')}
                    name="phone_no"
                    rules={[
                        {
                            required: true,
                            validator: (rule, value) => validatePhoneNumber(rule, value),
                        },
                    ]}
                >
                    <Input className="custom-input" />
                </Form.Item>
                {currentType === UserTypes.BUYER || currentType === UserTypes.SELLER ? (
                    <Form.Item
                        extra="tosend details"
                        label={t('Register.label5')}
                        name="email"
                        rules={[
                            {
                                validator: (rule, value) => emailRequired(rule, value),
                            },
                        ]}
                    >
                        <Input className="custom-input" />
                    </Form.Item>
                ) : null}
                <Form.Item
                    {...registerBasicFormTailLayout}
                    name="remember"
                    valuePropName="checked"
                    rules={[
                        {
                            required: true,
                            validator: (rule, value) => customConsentValidator(rule, value),
                        },
                    ]}
                >
                    <Checkbox className="custom-checkbox">
                        {t('Register.title2')}
                        <Button type="link" onClick={() => setTandC(true)}>
                            {t('Register.title3')}
                        </Button>
                        {t('Register.title4')}
                    </Checkbox>
                </Form.Item>
                <Form.Item {...registerBasicFormTailLayout}>
                    <div style= {{display:'flex',flexDirection:'row',justifyContent: 'space-between'}}>
                        <PrimaryBtn
                            style={{ width: '48%' }}
                            htmlType={t('Register.content5')}
                            content={t('Register.content3')}
                        />
                        <DefaultBtn
                            style={{ width: '48%' }}
                            onClick={() => {
                                setSignUpPopupVisible(false);
                                form.resetFields();
                            }}
                            content={t('Register.content4')}
                        />
                    </div>
                </Form.Item>
            </Form>
            {displayTandC && <TAndCPopup initialDisplayType="general" viewTAndC={displayTandC} />}
        </React.Fragment>
    );
};

export default Register;
